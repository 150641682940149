import { AvailableLanguages } from 'lib/enums';

export const APP_ROOT = 'root';

// TODO: change to www
// - here
// - in Admin panel: config + traducteurs help message
// - in .htaccess files
// - in `yarn send` command
export const API_HOST = 'https://v2.astj.ch/api/public';

export const API_ENDPOINT = `${API_HOST}/astj/`;

export const DEFAULT_LANGUAGE = AvailableLanguages.FR;

export const EDITOR_ROLE_ID = 4;

export const ILLUSTRATION_SETTINGS = {
  FULL: 300,
  SUMMARY: 200,
};

export const LOCALSTORAGE_FIELDS = {
  AUTH_METHOD: 'auth-method',
  USER: 'user-id',
  ROLE: 'role-id',
};
